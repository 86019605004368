<template>
    <div class="" id="passwordchange">
        <img class="logo" :src="require('@/assets/logo.svg')" />

        <form class="login_form" @submit.prevent="submit">
            <h1>Passwort ändern</h1>
            <p class="form-fieldwrap">
                <label>neues Passwort</label>
                <input :type="inputType" v-model="newPass" autocomplete="new-password" @blur="checkPass">
                <span @click="showPass = !showPass" class="icon"><inline-svg :src="icon" height="16" /></span>
            </p>
            <p class="form-fieldwrap">
                <label>Kontroll Passwort</label>
                <input :type="inputType" v-model="newPassRepeat" autocomplete="new-password" @blur="checkPassCntrl">
                <span @click="showPass = !showPass" class="icon"><inline-svg :src="icon" height="16" /></span>
            </p>
            <p class="error" v-if="errorMsg">{{$t(errorMsg)}}</p>
            <p class="submit">
                <button type="submit" class="button button--primary" :disabled="!passwordsIdentic">Abschicken</button>
                <router-link :to="{name:'Login'}" class="button button--text">{{$t('back')}}</router-link>
            </p>
        </form>
    </div>
</template>

<script>
import api_call, { ApiDefaults } from '../utils/api_call';

export default {
	name: "PasswordChange",
	data() {
		return {
			hash: null,
			newPass: '',
			newPassRepeat: '',
            showPass: false,
            errorMsg: false
        };
	},
	props: [],
    computed:{
		inputType: function(){
			return ( this.showPass ? 'text' : 'password');
        },
        icon: function(){
            return ( this.showPass ? require('@/assets/images/ic_visibility.svg') : require('@/assets/images/ic_visibility_off.svg'));
        },
        passwordsIdentic: function(){
			return this.newPass.toString().length > 5 && this.newPass === this.newPassRepeat;
        }
    },
	methods: {
		checkPass: function(){
			if( this.newPass.length < 6 ) {
				this.errorMsg = this.$t('error_passwort_short');
            }
            else{
				this.errorMsg = false;
            }
        },
		checkPassCntrl: function(){
			if( ! this.passwordsIdentic ){
				this.errorMsg = this.$t('error_passwort_not_same');
            }
			else{
				this.errorMsg = false;
            }
        },
		submit : function(){
            if( this.passwordsIdentic ) {
				let ApiParams = Object.assign({}, ApiDefaults, {
						method: 'post',
						params: {
							id: 1,
                            type: 5
						},
						data: null
					}),
					postData = new FormData();

				postData.append('tx_felogin_login[action]', 'changePassword');
				postData.append('tx_felogin_login[controller]', 'PasswordRecovery');
				postData.append('tx_felogin_login[newPass]', this.newPass);
				postData.append('tx_felogin_login[newPassRepeat]', this.newPassRepeat);
				postData.append('tx_felogin_login[hash]', this.hash);

				ApiParams.data = postData;

				api_call(ApiParams)
					.then((response) => {
						if( response.status === 200 && response.data.status === 'OK'){

							this.$router.push({name: 'Login'});
                        }
						else if( response.status === 200 && response.data.status === 'ERROR'){

							this.errorMsg = response.data.error;
						}
					})
					.catch(() => {
					});
			}
			else {
				this.errorMsg = 'passwords_not_identic';
            }
        }
    },
    mounted (){
		if( false !== Object.hasOwnProperty.call(this.$route.query,'hash')) {
			this.hash = this.$route.query.hash;
		}
    }
};
</script>

<style scoped>
#passwordchange{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    max-width: 360px;
    margin: 0 auto;
}
.form-fieldwrap .icon {
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -8px;
}
.form-fieldwrap .icon svg {
    fill: #666;
}
</style>